import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { strings } from "common/constants";
import { APIMethods } from "common/services/api";
import { useAsyncState } from "hooks";
import { getAdminSession, clearAdminSession, ensureArray } from "common/utils";
import { MARKETPLACE, ADMIN } from "common/constants/globals";
import { createInstance } from "./utils";
import { ENV } from "common/constants/strings";

const APIContext = createContext();

const MARKET_PLACE = MARKETPLACE.toLowerCase();
const {
  platform: initialPlatform = ADMIN,
  client: initialClient = "empty",
  restaurantGroupId: initialClientId = "empty",
} = getAdminSession();

const findAppId = (arr = [], client) =>
  arr.findIndex((item) => item.appId === client);

const DEFAULT_FILTERS_MARKETPLACE = {
  filters: {
    name: "",
  },
  limit: 0,
};

const DEFAULT_FILTERS = {
  filters: {
    name: "",
  },
  limit: 0,
};

const Provider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [activeLocationID, setActiveLocationID] = useState("");
  const [usersLocations, setUsersLocations] = useAsyncState({
    state: [],
    fetching: true,
  });
  const [usersRestaurantGroups, setUsersRestaurantGroups] = useAsyncState({
    state: null,
  });

  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);
  const [client, setClient] = useState(() => initialClient);
  const [platform, setPlatform] = useState(() => initialPlatform);
  // initilialize the RG id : by default it is set to "empty"
  const [restaurantGroupId, setRestaurantGroupId] = useState(
    () => initialClientId,
  );

  // production
  const isProduction = process.env.REACT_APP_ENV === ENV.PRODUCTION;

  // Add client ,platform and restaurantGroupId to the headers request
  const apiMethods = useMemo(() => {
    const axiosInstance = createInstance({ client, platform });
    return {
      ...APIMethods(axiosInstance),
      axiosInstance,
    };
  }, [client, platform]);

  // when user change platform
  // we update : client,platform and RG id
  const onChangePlatform = (nextClient, nextPlatform, nextClientId) => {
    setClient(nextClient);
    setPlatform(nextPlatform);
    setRestaurantGroupId(nextClientId);
  };

  const onActiveLocationChange = (id) => setActiveLocationID(id);

  const logout = useCallback(async () => {
    try {
      await apiMethods.Admins.signOut();
      setUser(null);
      setActiveLocationID("");
      clearAdminSession();
    } catch (e) {
      console.error(e);
    } finally {
      setFetching(false);
    }
  }, [apiMethods.Admins]);

  const update = useCallback(
    async (values) => {
      try {
        const { data } = values
          ? await apiMethods.Admins.update(user.id, values)
          : await apiMethods.Admins.readOne(user.id, values);
        setUser(data);
        setActiveLocationID("");
      } catch (e) {
        console.log(e);
      }
    },
    [apiMethods.Admins],
  );

  const validate = useCallback(async () => {
    setFetching(true);
    try {
      const { data } = await apiMethods.Admins.validate();
      let { restaurantGroups = [], marketplaces = [] } = data;
      restaurantGroups = ensureArray(restaurantGroups);
      marketplaces = ensureArray(marketplaces);

      const appIdSource =
        platform === MARKET_PLACE ? marketplaces : restaurantGroups;
      const sessionMatchesStorage = findAppId(appIdSource, client) !== -1;
      // Set the default values for client and platform if
      // we dont match anything in sessionstorage
      // Marketplace gets precedence over restaurantGroups
      if (client === strings.STATUS.EMPTY && !sessionMatchesStorage) {
        if (marketplaces[0]?.appId) {
          onChangePlatform(
            marketplaces[0]?.appId,
            MARKET_PLACE,
            marketplaces[0]?.id,
          );
        } else if (restaurantGroups[0]?.appId) {
          onChangePlatform(
            restaurantGroups[0].appId,
            ADMIN,
            restaurantGroups[0]?.id,
          );
        } else {
          throw new Error(
            "There is problem with your account. Please contact customer support",
          );
        }
      }
      setUser(data);
    } catch (e) {
      const { response: { data: { message = "" } = {} } = {} } = e;
      if (!message.includes("you need to be logged in")) {
        console.error(e);
        setError(true);
      }
    } finally {
      setFetching(false);
    }
  }, [apiMethods.Admins, client, platform]);

  const getRestaurantInformation = useCallback(async () => {
    setUsersRestaurantGroups.setIsFetching();
    try {
      if (user) {
        const restaurantGroupRes = await apiMethods.RestaurantGroups.readOne(
          user.restaurantGroup,
        );
        // set the restaurantGroupId when the component mount
        setRestaurantGroupId(restaurantGroupRes?.data?.id);
        setUsersRestaurantGroups.setState(restaurantGroupRes?.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setUsersRestaurantGroups.setNotFetching();
    }
  }, [apiMethods, platform, user]);

  const getClientLocations = useCallback(async () => {
    setUsersLocations.setIsFetching();
    try {
      if (user) {
        const isMarketplace = platform === MARKET_PLACE;
        const { data } = await apiMethods[
          isMarketplace ? "GhostKitchens" : "Locations"
        ].readMany(
          isMarketplace ? DEFAULT_FILTERS_MARKETPLACE : DEFAULT_FILTERS,
        );
        const locationData = data.results.map(
          ({ name, id, isActive, phone }) => ({
            name,
            id,
            isActive,
            phone,
          }),
        );
        setUsersLocations.setState(locationData);
        if (locationData.length === 1) {
          setActiveLocationID(locationData[0].id);
        } else {
          setActiveLocationID("");
        }
      }
    } catch (e) {
      setUsersLocations.setError(e);
      console.error(e);
    } finally {
      setUsersLocations.setNotFetching();
    }
  }, [apiMethods, platform, user]);

  useEffect(() => {
    if (!fetching) {
      sessionStorage.setItem(
        "platform",
        JSON.stringify({ client, platform, restaurantGroupId }),
      );
      getRestaurantInformation();
      getClientLocations();
    }
  }, [
    client,
    platform,
    fetching,
    getRestaurantInformation,
    getClientLocations,
  ]);

  useEffect(() => {
    validate();
  }, [validate]);

  const contextValues = {
    user,
    platform,
    client,
    fetching: usersRestaurantGroups.fetching,
    activeLocationID,
    usersLocations: usersLocations.state,
    usersRestaurantGroups: usersRestaurantGroups.state,
    setUser,
    logout,
    update,
    onChangePlatform,
    onActiveLocationChange,
    isProduction,
    restaurantGroupId, // add the restaurantGroupId in case we need it
    ...apiMethods,
  };

  return (
    <APIContext.Provider value={contextValues}>{children}</APIContext.Provider>
  );
};

const useAPIContext = () => {
  const contextValues = useContext(APIContext);
  if (!contextValues) {
    throw new Error("useAPIContext must be used withing a APIContextProvider.");
  }
  return contextValues;
};

export { useAPIContext, APIContext, Provider as APIContextProvider };
export default {
  Provider,
};
